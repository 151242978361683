.nav-header {
  --space: 10px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  background-color: #4a69bd;
}

.nav-header a {
  color: #fff;
  text-decoration: none;
}

.bold {
  font-weight: 700;
}

.nav-header-optionals {
  margin: 0;
  padding: 0;
}

.nav-button {
  height: 100%;
  border: none;
  font-size: 16px;
  padding: 5px 10px;
  margin: 0 0 0 var(--space);
  font-family: inherit;
  border-radius: 10px;

  span.twitter-link {
    color: #1DA1F2
  }
}

a[disabled] .nav-button {
  color: var(--button-disabled-background);
  pointer-events: none;
}
a[disabled] {
  pointer-events: none;
  display: flex;
  align-items: center;
}

.nav-title {
  margin-right: auto;
  margin-left: var(--space);
  align-items: center;

  a {
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
  }

  a:not(:first-of-type) {
    margin-left: 20px;
  }

  img {
    margin-right: 10px;
    height: 60px;
    width: 40px;
  }
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    margin: 0 10px;
  }
}

/* MOBILE STYLES */

@media(max-width: 768px) {

    .nav-title{
      align-items: center;
      justify-content: space-between;
      margin: auto;
      img {
        margin-right: 0px;
      }
      a {
        margin: 0 10px;
      }
      a:not(:first-of-type) {
        margin-left: 10px;
      }
    }

    .nav-links{
      margin: auto;
    }

    .nav-button {
        font-size: 14px;
    }

    .nav-links a {
        margin: 0 5px;
        font-size: 13px;
    }
}



@media(max-width: 550px) {
    .nav-title{

        a {
            flex-direction: column;
            font-size: 14px;
        }

        span {
            display: none;
        }

        img {
            width: 40px;
        }
    }

    .nav-button {
        font-size: 13px;
        margin: 0;
    }

    .nav-header {
        padding: 0 20px;
    }

    img .nav-header-icon {
        height: 30px;
    }
      
}

@media(max-width: 414px) { 
    .nav-title {
        img {
            width: 35px;
        }
    }

    .nav-header {
        padding: 5px 20px;
    }

}
