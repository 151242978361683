.footer {
  margin-top: 60px;
  width: 100vw;
  color: #fff;
}

.footer-container {
  margin: 0 auto;
  max-width: 960px;
  padding: 60px 20px;
}

.footer-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  row-gap: 15px;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer a {
  text-decoration: none;
  margin-bottom: 10px;
  color: inherit;
}

/* MOBILE STYLES */

@media(max-width: 550px) {
    .footer {
        margin-top: 10px;
        font-size: 12px;
      }
      
}

@media(max-width: 414px) {
    .footer {
        margin-top: 10px;
        font-size: 14px;
      }

      .footer-container {
          padding: 20px 30px;
       }

      .footer-content {
        grid-template-columns: 1fr 1fr;
        row-gap: 2px;
        justify-items: flex-start;
      }
      
}
