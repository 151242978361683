body {
  background: var(--page-background);
}

.fixit {  
  width: var(--app-width);
  height: var(--app-height);
  background: var(--page-background);
  padding: calc(2*var(--elem-margin));
  display: flex;
  justify-content: center;
}

.fixit-title {
    align-items: center;
}

.mobile-error {
  align-items: top;
}