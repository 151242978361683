.close-video-container {
    display: flex;
    justify-content: flex-end;
}

.close-video {
    all: unset;
    margin-bottom: 2rem;
    text-align: right;
    cursor: pointer;
}

.close-video img {
    height: 20px;
}

.video-container {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 100%;
    /* overflow: hidden; */
    padding-top: 46.25%; /* 16:9 Aspect Ratio */
}

@media(max-width: 768px) {
    .video-container {
        padding-top: 56.25%;
    }
}