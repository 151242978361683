:root {
  --app-width: 1194px;
  --app-height: 834px;
  --modals-max-width: 500px;

  --elem-margin: 5px;

  --font-family: 'Nunito', sans-serif;

  --text-color: #333;
  --page-background: #f0f0f0;
  --launch-app-font-color: white;

  --accent-cta-background: #4f8ed9;
  --accent-cta-hover-background: #5a9beb;
  --primary-cta-background: #61a165;
  --primary-cta-hover-background: #6faf6f;
  --primary-cta-foreground: #fff;
  --secondary-cta-background: #fff;
  --secondary-cta-hover-background: #fafafa;
  --danger-cta-background: #f66055;
  --danger-cta-hover-background: #ff6e58;
  --secondary-cta-foreground: #61a165;
  --button-disabled-background: #aaa;
  --button-disabled-foreground: #fff;

  --inactive-element-background: #e3e3e3;

  /* REDESIGN */

  --ifixit-new-blue: #4a69bd;
  --ifixit-new-light-blue: #6e87ca;
  --ifixit-new-green: #8cba76;
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.horizonal-flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.hidden {
  visibility: hidden;
}

.rotated {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.info-page {
  padding: 30px;
  max-width: 1024px;
}

.green-font {
  color: var(--fixit-green);
}

.hfill {
  width: 100%;
}

.vfill {
  height: 100%;
}

.centered {
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.left {
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.justified {
  justify-content: center;
  align-items: center;
  text-align: justify;
}

.rounded {
  border-radius: 10px;
}

.underline {
  text-decoration: underline;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.notification-container {
  font-size: 1em;
  height: 3em;
  background: #fff;
  justify-content: center;
}

.notification-container p {
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: black;
}

.ifixit-gradient {
  background: linear-gradient(
    var(--ifixit-new-blue),
    var(--ifixit-new-light-blue)
  );
}

*,
*:focus,
*:hover {
  outline: none;
}

.dot {
  border-radius: 50%;
  display: inline-block;
}

.line {
  border-bottom: 4px dashed #bbb;
}

.text-success {
  color: var(--primary-cta-background);
}

.text-center {
  text-align: center;
}

.text-danger {
  color: var(--danger-cta-background);
}

.text-bold {
  font-weight: bold;
}

.text-bigger {
  font-size: 1.05em;
}

.no-margin {
  margin: 0;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 var(--fixit-red);
  }

  70% {
    box-shadow: 0 0 0 5px var(--fixit-red);
  }

  100% {
    box-shadow: 0 0 0 0 var(--fixit-red);
  }
}

@keyframes pulse-red-inset {
  0% {
    box-shadow: inset 0 0 0 0 var(--fixit-red);
  }

  70% {
    box-shadow: inset 0 0 0 5px var(--fixit-red);
  }

  100% {
    box-shadow: inset 0 0 0 0 var(--fixit-red);
  }
}
