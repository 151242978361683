%button {
  box-sizing: border-box;
  cursor: pointer;

  font-size: 1em;
  height: auto;
  padding: var(--elem-margin);

  border: none;

  &:disabled {
    cursor: not-allowed;
  }
}

@mixin colored-button(
  $color-background,
  $color-background-hover,
  $color-foreground: var(--primary-cta-foreground),
  $color-background-disabled: var(--button-disabled-background),
  $color-foreground-disabled: var(--button-disabled-foreground)
) {
  background: $color-background;
  color: $color-foreground;

  &:hover:not(:disabled):not([disabled]) {
    background: $color-background-hover;
  }

  &:disabled,
  &[disabled] {
    background: $color-background-disabled;
    color: $color-foreground-disabled;
  }

  &.outlined {
    background: transparent;
    color: $color-background;
    border-color: $color-background;
    &:hover:not(:disabled):not([disabled]) {
      background: $color-background-hover;
      border-color: $color-background-hover;
      color: $color-foreground;
    }
    &:disabled,
    &[disabled] {
      color: $color-background-disabled;
      border-color: $color-background-disabled;
    }
  }
}

.primary-button {
  @extend %button;
  @include colored-button(
    var(--ifixit-new-green),
    var(--primary-cta-hover-background)
  );
}

.secondary-button {
  @extend %button;
  @include colored-button(
    var(--secondary-cta-background),
    var(--secondary-cta-hover-background),
    var(--secondary-cta-foreground)
  );
}

.blue-button {
  @extend %button;
  @include colored-button(
    var(--ifixit-new-light-blue),
    var(--ifixit-new-blue)
  );
}

.disabled-button {
  @extend %button;
  @include colored-button(
    var(--button-disabled-background),
    var(--button-disabled-background),
    var(--button-disabled-foreground)
  );
}

.button {
  @extend %button;
  $padding-x: 10px;
  $padding-y: 10px;
  $border: 1px;

  border-radius: 8px;

  line-height: 1em;
  font-size: 1rem;
  font-family: inherit;
  padding: $padding-y $padding-x;

  &.outlined {
    padding: #{$padding-y - $border} #{$padding-x - $border};
    border: $border solid;
  }

  &.full-width {
    width: 100%;
    display: block;
  }

  &.margin-x {
    margin: 0 10px;
  }

  &.margin-y {
    margin: 10px 0;
  }

  &.primary {
    @include colored-button(
      var(--primary-cta-background),
      var(--primary-cta-hover-background)
    );
  }
  &.secondary {
    @include colored-button(
      var(--secondary-cta-background),
      var(--secondary-cta-hover-background),
      var(--secondary-cta-foreground)
    );
  }
  &.accent {
    @include colored-button(
      var(--accent-cta-background),
      var(--accent-cta-hover-background)
    );
  }
  &.danger {
    @include colored-button(
      var(--danger-cta-background),
      var(--danger-cta-hover-background)
    );
  }
}

a.button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  &[disabled] {
    pointer-events: none;
  }
}

.button-group {
  &.right {
    text-align: right;
  }
  .button {
    min-width: 130px;
  }
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #4285f4;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

%history-button {
  color: white;
  font-size: 12px;
  padding: 4px 10px;
  margin: 2px;
  display: flex;
  cursor: pointer;
  justify-content: space-evenly;
  img {
    width: 1.5em;
    height: 1.5em;
  }
}

.profile-history-incident-button {  
  @extend %history-button;
  background: var(--ifixit-new-green);
  &:hover {
    background: var(--fixit-green-highlight);
    text-decoration: underline;
  }
}

.profile-history-award-button {  
  @extend %history-button;
  background: var(--ifixit-new-light-blue);
  &:hover {
    background: var(--ifixit-new-blue);
    text-decoration: underline;
  }
}
