.error-modal-overlay {
    --modal-width: 655px;
    --modal-height: 490px;
    --error-ok-button-height: 140px;
}

.error-modal {
    --modal-primary-color: #FFFFFF;
    --modal-secondary-color: #61A165;
    width: var(--modal-width);
    height: var(--modal-height);
}

.error-content {
    width: 100%;
    height: calc(100% - var(--error-ok-button-height));
}

.error-ok-button {
    width: 100%;
    height: var(--error-ok-button-height);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    font-size: 1.25em;
    border: none;
    background: var(--accent-cta-background);
    color: var(--launch-app-font-color); 
}
