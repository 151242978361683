.cGKbZh {
  background-color: #333 !important;
  color: #fff !important;
}

.rec-dot {
  height: 10px !important;
  width: 10px !important;
}

.rec-dot_active {
  background-color: var(--ifixit-new-light-blue) !important;
}

.jLgnwX {
  box-shadow: none !important;
  background-color: #ddd !important;
}

.ejXxNI {
  box-shadow: none !important;
  border: 2px solid transparent !important;
}
