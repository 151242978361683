.homepage {
  --homepage-font-family: 'Nunito', sans-serif;
  --homepage-font-size: 2em;
  --section-padding: 20px;
  --icon-padding: 40px;
  --title-icon-size: 478px;
  --process-icon-size: 140px;
  --process-icon-spacer-dot-size: 20px;
  --video-width: 480px;
  --video-height: calc(var(--video-width) * 0.5625);
  --homepage-primary-color: #ffffff;
  --homepage-secondary-color: #61a165;
  --homepage-title-color: #61a165;
  --homepage-text-color: #616161;
  background: var(--homepage-primary-color);
  width: 100vw;
}

.homepage-intro {
  margin: 0 auto;
  padding-top: 105px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 80px;
}

.homepage-intro-container {
  max-width: 1200px;
  padding: 20px;
}

.homepage-intro-title {
  color: #fff;
  font-weight: 700;
  font-size: 58px;
  text-align: center;
}

.homepage-intro-text {
  color: #fff;
  font-size: 22px;
  text-align: center;
  width: 100%;
}

.homepage-small-text {
  color: #fff;
  font-size: 18px;
  text-align: center;
  width: 100%;
}

.homepage-intro-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

.homepage-btn {
  color: var(--launch-app-font-color);
  font-size: 18px;
  border-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: inherit;
  padding: 25px;
  cursor: pointer;
  text-decoration: none;
}

.green-button {
  background: var(--ifixit-new-green);
  color: var(--launch-app-font-color);
  border: none;
  margin-right: 10px;
}

.blue-btn {
  background-color: var(--ifixit-new-blue);
  color: var(--launch-app-font-color);
  border: 2px solid #4a619e;
  margin-left: 10px;
}

.blue-btn img {
  margin-right: 5px;
  width: 25px;
}

.title-icon-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 120px;
}

.title-icon {
  height: auto;
  width: 80%;
}

.homepage-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.homepage-process {
  padding: 100px 0;
  width: 100%;
  margin: 0 auto;
}

.homepage-process-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.homepage-process-img {
    height: auto;
    width: 100%;
}


.homepage-section-head-text {
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 100px;
  text-align: center;
}

.light {
  color: #fff;
}

.homepage-details {
  width: 100%;
  padding: 100px 0px;
}

.homepage-details-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.homepage-details-columns {
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 100px;
}

@media (max-width: 767px) {
  .homepage-details-columns {
    grid-template-columns: repeat(1, 1fr);
  }
}

.homepage-column {
  display: grid;
  justify-items: center;
  align-items: center;
}

.homepage-column h3 {
  font-size: 18px;
}

.homepage-column-img {
  margin-top: 40px;
  width: 60%;
  height: auto;
}

.homepage-column-benefits {
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
}

.homepage-column-item-img {
  width: 30px;
  height: 30px;
  margin-top: 0;
  margin-right: 20px;
}

.homepage-column-item {
  color: #fff;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.homepage-column-item span {
  font-size: 18px;
}

.homepage-learn {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.homepage-testimonials {
  width: 100%;
  padding: 100px 0px;
}

.homepage-testimonial-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.testimonial {
  z-index: 4;
  padding: 0px 40px;
  margin-bottom: 10px;
  border-radius: 8px;
}

.testimonial p,
.testimonial li {
  text-align: left;
  line-height: 28px;
  color: #787878;
  font-style: italic;
  font-size: 18px;
}

.testimonial-top {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.testimonial-profile {
  display: flex;
  flex-direction: column;
}

p.testimonial-job {
  color: #6d90d6;
  font-weight: 600;
  margin: 0;
}

p.testimonial-name {
  color: #333;
  font-weight: 600;
  margin: 0;
}

#mega-testimonial p,
#mega-testimonial li {
  font-size: 14px;
  line-height: 24px;
}




/* MOBILE STYLES */


/* IPAD */
@media(max-width: 768px) {
    .homepage-intro-title {
        font-size: 50px;
    }

    .homepage-intro-text {
        font-size: 18px;
    }

    .homepage-small-text {
        font-size: 14px;
    }

    .homepage-btn {
        font-size: 14px;
        padding: 15px;
    }

    .homepage-section-head-text {
        font-size: 28px;
    }

    .homepage-testimonial-container {
        padding: 0 5px;
    }
}

/* IPHONE 6/7/8 PLUS */
@media(max-width: 414px) {

    .homepage-intro-title {
        font-size: 34px;
        text-align: left;
    }

    .homepage-intro-text {
        font-size: 16px;
        text-align: left;
    }

    .homepage-small-text {
        font-size: 12px;
    }

    .homepage-btn {
        font-size: 12px;
    }

    .blue-btn img {
        width: 20px;
    }

    .title-icon-container {
        margin-top: 60px;
    }

    .homepage-section-head-text {
        font-size: 20px;
        margin-bottom: 40px;
    }

    .homepage-intro, .homepage-process, .homepage-details, .homepage-testimonials {
        padding: 40px 0;
    }

    .homepage-column-img {
        width: 50%;
    }

    .homepage-column-item span {
        font-size: 14px;
    }

    .sc-bdnxRM  {
        display: none;
    }

    .homepage-testimonial-container {
        padding: 0 5px;
    }

    .testimonial p {
        font-size: 14px;
    }
}