:root {
  
  --app-width: 1194px;
  --app-height: 834px;

  --question-icon-size: 128px;
  --title-width: 700px;
  --answer-icon-size: 98px;
  --section-margin:10px;
  --fullscreen-margin: 10px;
  --fullscreen-width: calc(var(--app-width) - 2*var(--fullscreen-margin));
  --header-text-margin: 5px;
  --sticker-text-margin: 5px;
  --question-font-size: 2em;
  --sticker-font-size: 1em;
  --sticker-title-height: calc(var(--sticker-title-lines) * var(--sticker-font-size) + 1em);
  --sticker-title-lines: 2;
  --question-page-border-width: 2px;
  --answer-sticker-width: calc(160px + 2*var(--elem-margin));
  --answer-sticker-height: calc(160px + 2*var(--elem-margin) - 2*var(--question-page-border-width));
  --background: #F0F0F0;
  --icon-background: #E3E3E3;
  
  display: flex;
  justify-content: center;
  align-items: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.section {
  margin: var(--section-margin);
  width: var(--fullscreen-width);
}

.question-header-title {
  background: var(--background);
  font-size: var(--question-font-size);
  font-family: var(--font-family);
  text-align: center;
  border: var(--question-page-border-width) solid black;
  border-radius: var(--border-radius);
  width: var(--title-width);
  height: calc(var(--question-icon-size) + 2*var(--elem-margin));
  margin: 0px var(--header-text-margin);

  span {
    padding: var(--header-text-margin);
  }
}

.question-icon {
  background: var(--background);
  border: var(--question-page-border-width) solid black;
  border-radius: var(--border-radius);
  width: var(--question-icon-size);
  height: var(--question-icon-size);
  padding: var(--elem-margin);
  margin: 0px var(--header-text-margin);
}

.chosen-answers-container {
  width: calc(3*var(--answer-sticker-width) + 4*var(--elem-margin) + 6*var(--question-page-border-width) + 2*var(--elem-margin));
  height: calc(var(--answer-sticker-height) + 2*(var(--elem-margin)));
  background: var(--page-background);
  border: var(--question-page-border-width) solid black;
  border-radius: var(--border-radius);
}

.possible-answers-container {
  min-height: calc(var(--answer-sticker-height) + 2*(var(--elem-margin)));
  margin: var(--elem-margin) calc(2*var(--elem-margin));
}

.sticker-background {
  background: none;
  border-radius: var(--border-radius);
  width: var(--answer-sticker-width);
  height: var(--answer-sticker-height);
  margin: calc(var(--elem-margin));
  transform: translate(0, 0);
}

.sticker-with-background {
  background: var(--icon-background);
}

.sticker {
  background: none;
  border-radius: var(--border-radius);
  width: var(--answer-sticker-width);
  margin: var(--elem-margin);
}

.spinner {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.sticker-title {
  font-size: var(--sticker-font-size);
  font-family: var(--font-family);
  text-align: center;
}

.sticker-title > span {
  padding: var(--elem-margin);
  height: var(--sticker-title-height);
}

.is-dragging-over {
  background: lightblue;
}

.answer-icon {
  position: relative;
  display: block;
  justify-content: center;
  width: var(--answer-icon-size);
  height: var(--answer-icon-size);
  margin-top: var(--elem-margin);
  margin-left: auto;
  margin-right: auto;
}

.chosen-answer {
  width: var(--answer-sticker-height);
  height: var(--answer-sticker-height);
  margin: var(--elem-margin);
  border: none;
  background: none;
}

.chosen-answer > img {
  width: 100%;
  height: 100%;
}

.layer-styles {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.placeholder-sticker {
  background: var(--icon-background);
  border-radius: var(--border-radius);
  border: var(--question-page-border-width) dotted black;
  width: var(--answer-sticker-width);
  height: var(--answer-sticker-height);
  margin: calc(var(--elem-margin) - var(--question-page-border-width));
}
