.signin-modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-in-divider{
  padding: .5rem;
  background: var(--fixit-blue);
  border-radius: 50%;
  color: white;
  margin: 20px;
}

.sign-in-container{
  width: var(--segment-width);
//   height: var(--segment-height);
  padding: var(--segment-margin);
}

.sign-in-container p {
    margin: 0;
}

.sign-in-button {
  width: calc(100% - 2*var(--input-margin));
}

.sign-in-terms {
  width: calc(100% - 2*var(--input-margin));
  margin: var(--input-margin);
}

.small-text {
  font-size: 0.75em;
}

.signin-modal {
  --modal-primary-color: #FFFFFF;
  --modal-secondary-color: #61A165;
  --modal-width: 900px;
  --modal-height: 420px;
  --divider-width: 50px;
  --segment-margin: 20px;
  --segment-width: calc((var(--modal-width) - var(--divider-width) - var(--segment-margin) * 4)/2);
  --segment-height: calc(var(--modal-height) - var(--segment-margin) * 2);
  --input-margin: 15px;
  max-width: 98%;
  font-size: 1em;

  .text-danger, .text-muted{
    font-size: 1rem;
    margin-bottom: 0;
  }

  .cancel {
    margin-top: 8px;
  }
  
  .reset {
    margin-top: 16px;
  }

  input[type=email], input[type=password] {
    width: calc(100% - 2*var(--input-margin));
    margin: var(--input-margin);
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid black;
    background: none;

    &.danger{
      border-bottom: 1px solid var(--danger-cta-background);
    }
  }

  input[type='checkbox'] { 
    --signin-checkbox-size: 20px;
    width: var(--signin-checkbox-size);
    height: var(--signin-checkbox-size);
    margin: 0 0 0 var(--input-margin);
    align-self: flex-end;
  }
}

@media(max-width: 1024px) {

    .signin-modal-content {
        flex-direction: column;
    }
}
