%input {
  box-sizing: border-box;
  height: 26px;
  border-radius: 4px;
  border: 1px solid #c3c3c3;
  color: var(--regular-text-color);
  background-color: white;
  padding: 0px 10px;
  margin-bottom: 20px;
  width: 100%;
  font-family: 'Nunito', sans-serif;
}

.header-pill-container {
  width: 100%;
  text-align: center;
}
.header-pill {
  display: inline-block;

  $padding-x: 55px;
  $padding-y: 16px;

  border-radius: 8px;

  line-height: 1em;
  font-size: 1.05em;

  padding: $padding-y $padding-x;
  margin-top: 56px;
  margin-bottom: 53px;

  &.danger {
    color: white;
    background-color: var(--danger-cta-background);
  }

  &.regular {
    color: var(--text-color);
    background-color: var(--inactive-element-background);
  }
}

.editable-section .edit {
  color: var(--primary-cta-background);
  float: right;
  cursor: pointer;
}

.editable-form {
  .display {
    margin-top: 20px;
    padding-left: 20px;
  }

  .form {
    margin-top: 5px;

    label {
      color: #8e8e8e;
      margin-bottom: 8px;
      display: block;
    }

    input,
    select {
      width: calc(100% - 10px) !important;
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }

  .button-group {
    margin-top: 20px;
  }
}

.text-field, .text-area {
  input,
  textarea,
  select {
    @extend %input;
  }

  textarea{
    font-size: 1em;
    resize: none;
    padding: 10px;
    height: 100%;
  }

  select {
    padding: 0 6px;
  }

  .text-field-input-profiles {
    width: 20%;
    text-align: center;
  }
  .text-field-input-password {
    background-color: white;
    text-align: center;
    margin: 10px;
  }
  .text-area-input-notes, .text-area-input-incident-notes  {
    margin-bottom: 0px;
    height: 4.5em;
  }
  .text-area-input-limit {
    text-align: right;
    margin: 0px;
  }
  :disabled {
    background-color: var(--button-disabled-background);
  }
}

.text-area-notes {
  width: 846px;
  height: 100px;
}

.text-area-incident-notes {
  width: 100%;
  margin: 0px var(--elem-margin)
}

.card-input {
  height: 26px;
  display: flex;
  flex-flow: column;
  place-content: center;
}

.settings {
  color: var(--text-color);
  &.columns {
    margin: var(--inner-margin-size);
  }

  .subscription {
    .title {
      font-size: 1.05em;
      margin-bottom: 10px;
    }
  }

  .details {
    .editable-form:not(:last-of-type) {
      margin-bottom: 25px;
    }
  }
}

.columns {
  display: flex;
  flex-flow: row;
  max-width: 1000px;
  justify-content: space-around;
  width: 100%;

  .column {
    max-width: 440px;
    width: 100%;
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .separator {
    width: 2px;
    margin: 0 20px;
    height: auto;
    background-color: var(--inactive-element-background);
  }
}

.checkout-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 40px;
  thead th:not(:first-of-type) {
    text-align: left;
  }
  tbody td {
    padding: 5px 0;
  }
  .total {
    td:first-of-type {
      text-align: center;
      font-style: italic;
    }
    td {
      padding-top: 20px;
    }
  }
}

.dev-tool {
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
  background: repeating-linear-gradient(
    -45deg,
    #ffcc00,
    #ffcc00 10px,
    #000000 10px,
    #000000 20px
  );
}

%separator {
  background-color: var(--inactive-element-background);
  content: "";
  height: 2px;
  position: relative;
  vertical-align: middle;

  &.margin {
    $margin: 30px;
    margin-top: $margin;
    margin-bottom: $margin;
  }
}

.fancy-separator {
  @extend %separator;
  width: 100%;
}

.fancy-text {
  overflow: hidden;
  text-align: center;

  &:before,
  &:after {
    @extend %separator;
    content: "";
    display: inline-block;
    width: 50%;
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}
