.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    background: #E2E2E2;
    font-size:  1.25em;
    color: #888;
    border-radius: var(--border-radius);
    position: relative;
}
