:root {
    --title-font-size: 2em;
    --font-size: 1em;
    --icon-size: 64px;
    --text-margin: 5px;
    --summary-page-question-width: 200px;
    --summary-page-border-width: 3px;
    --summary-border-radius: 5px;
    --summary-question-height: calc(4em + 2*var(--text-margin) - 2*var(--summary-page-border-width));
    --summary-answer-container-height: calc(3 * var(--icon-size) + 6*var(--elem-margin));
    --summary-height: calc(var(--summary-question-height) + var(--summary-answer-container-height));
    --title-background: #F0F0F0;
}

.summary {
    ::-webkit-scrollbar {
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background: #BBB;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #999;
    }

    ::-webkit-scrollbar-thumb:active {
        background: #666;
    }

    @media print {
        @page {
            size: portrait;
        }
    }
}

.summary-incident-notes {
    max-width: var(--app-width);
    margin-bottom: 20px;
    word-wrap: break-word; 
}

.summary-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    justify-content: space-evenly;
    margin: var(--elem-margin);
}

.summary-incident {
    .summary-items {
        justify-content: space-evenly;
    }
}

.summary-coa {
    .summary-items {
        justify-content: center;
    }
}

.summary-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: calc(var(--summary-page-question-width) + 2*var(--elem-margin));
    height: var(--summary-height);

    border-radius: var(--summary-border-radius);
    border: var(--summary-page-border-width) solid black;
}

.summary-print-border {
    border: none;
    margin: var(--elem-margin);
}

.summary-text {
    font-size: var(--title-font-size);
    font-family: var(--font-family);
    font-weight: bold;
    text-align: center;
}

.summary-text-answer {
    text-align: start;
    word-wrap: break-word;
    width: 100%;
    max-width: 206px;
    max-height: 144px;
    padding: 2px;
    overflow-y: auto;
    scrollbar-width: thin;
}

.summary-coa-award-background {
    width: 800px;
    height: 400px;
}

.summary-done {
    text-decoration: none;
}

.summary-options {
    width: 100%;
}

.summary-button {
    font-size: 1.5em;
    width: 200px;
    height: 2em;
    margin: 0 1em;
}

.summary-question {
    align-items: center;
    font-size: var(--font-size);
    font-family: var(--font-family);
    text-align: center;
    width: 100%;
    height: var(--summary-question-height);
    p {        
        font-weight: bold;
        padding: 0 10px;
    }
}

.summary-icon {
    width: var(--icon-size);
    height: var(--icon-size);
    position: relative;
    display: block;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.summary-answers-container {
    height: calc(100% - var(--summary-question-height));

    hr {
        margin: 0;
        height: 2px;
        border: none;
    }
}

.summary-answer-title {
    width: calc(var(--summary-page-question-width) - var(--icon-size));
    height: var(--icon-size);
    font-family: var(--font-family);
    font-size: var(--font-size);
    justify-content: flex-start;
    align-items: center;
}

.summary-answer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: var(--elem-margin);
    span {        
        padding: var(--text-margin);
    }
}

.summary-answer-icon {
    display: block;
    justify-content: center;
    border: var(--summary-page-border-width) solid black;
    width: var(--icon-size);
    height: var(--icon-size);
    margin-left: auto;
    margin-right: auto;
}

.summary-background-colour {
    background: var(--title-background);
}

.certificate-of-achievement {
    @media print {
        @page {
            size: landscape;
        }
    }
}

@media print {

    @page {
        margin: 2cm;
    }

    p {
         padding: 0;
         margin: 0;     
    }

    .summary-text {
        font-size: 1.5em;
        font-family: var(--font-family);
        font-weight: bold;
        text-align: center;
    }

    .summary-items {
        display: block;
    }

    .summary-item {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
        height: 100%;
        border-radius: 0;
        border: none;
    }

    .summary-print-border {
        break-inside: avoid;
        margin: 2px;
        width: calc(100% - 2*var(--summary-page-border-width));
        height: 100%;
        padding: 1px;
        border-radius: var(--summary-border-radius);
        border: 1px dashed #CCC;
    }

    .summary-question {
        font-size: var(--font-size);
        font-family: var(--font-family);
        text-align: center;
        width: 33%;
        height: 100%;
        border: none;
        border-radius: 0;
        background: #FFF;
        margin: 0;
        padding: 0;
    }

    .summary-answers-container {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        border: none;
        border-radius: 0;
        width: 67%;
        height: 100%;
    }

    .summary-answer {
        display: block;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 1px;
        border: none;
        height: 100%;
        span {        
            width: 100%;
            padding: 0;
            text-align: center;
        }
    }

    .summary-answer-title {
        height: 100%;
    }
    
    .summary-icon {
        width: 48px;
        height: 48px;
        position: relative;
        display: block;
        justify-content: center;
        border: none;
        margin-left: auto;
        margin-right: auto;
    }

    .summary-text-answer {
        text-align: start;
        word-wrap: normal;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    .summary-background-colour {
        background: #FFF;
    }

    .summary-incident-notes {
        max-width: 100%;
        margin-bottom: 0;
        word-wrap: break-word; 
        p {
            text-align: start;
        }
    }
}
