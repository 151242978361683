.faq-footer {
  margin-top: 60px;
}

.faq-contact a {
  display: block;
  margin-top: 20px;
  font-weight: bold;
  color: var(--ifixit-new-blue);
}
