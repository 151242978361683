:root {  
  --app-width: 1194px;
  --app-height: 834px;
  --fullscreen-margin: 10px;
  --fullscreen-width: calc(var(--app-width) - 2*var(--fullscreen-margin));
  --border-radius: 25px;
  
  display: flex;
  justify-content: center;
  align-items: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.title-call-to-action {
  width: 100%;
  height: 100%;
  padding: var(--header-text-margin);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
}

.title-screen{
  background: var(--page-background);
  width: 75%;
  height: 75%;
}

svg {
  fill-rule:evenodd;
  clip-rule:evenodd;
  stroke-linejoin:round;
  stroke-miterlimit:2;
}

.title-text {
  font-family: var(--font-family);
  font-size: 4.5em;
  text-align: center;
  text-anchor: middle;
  fill:rgb(55,69,159);
}

.coa-name-text {
  font-family: var(--font-family);
  font-size: 5em;
  font-weight: bolder;
  text-align: center;
  text-anchor: middle;
  fill:rgb(208,67,58);
}

.coa-blurb-text {
  font-family: var(--font-family);
  font-size: 5em;
  text-align: center;
  text-anchor: middle;
  fill:rgb(246,238,228);
}

.launch-text {
  font-family: var(--font-family);
  font-size:3.5em;
  text-align:center;
  text-anchor:middle;
  fill:white;
}