.payment-method {
  $color: #ccc;
  background-color: $color;
  border: 1px solid darken($color, 10);
  padding: 5px 10px;
  margin: 10px 0;
  border-radius: 8px;

  &[data-default=true]{
    border-color: darken($color, 30);
  }

  .actions {
    float: right;

    & > .action {
      text-transform: lowercase;
      cursor: pointer;
      font-size: 0.95rem;
      margin-left: 5px;
      text-decoration: underline;
    }

    &[disabled] > .action {
      pointer-events: none;
      color: var(--button-disabled-background);
    }
  }

  .brand {
    text-transform: capitalize;
    text-align: center;
    display: inline-block;
    min-width: 110px;
  }
}
