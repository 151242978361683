:root{
    --fixit-green: #66A161;
    --fixit-green-highlight: #569151;
    --fixit-yellow: #eac458;
    --fixit-blue: #608ed0;
    --fixit-red: #F66055;
    --fixit-red-highlight: #FF6E58;
    --outer-margin-size: 30px;
    --inner-margin-size: 10px;
}

.create-profile-modal-overlay {
    --icon-option-size: 64px;    
    --icon-option-border: 5px;
    --icon-option-margin: 10px;
    --icon-option-padding: 5px;
    --modal-width: 500px;
    --modal-height: 350px;
    --disabled-button-background: #8c8c8c;
    --disabled-button-font-colour: #fff;
    --create-profile-button-height: 140px;
    --consent-checkbox-size: 22px;
}

.create-profile-modal {
    --modal-primary-color: #FFFFFF;
    --modal-secondary-color: var(--fixit-green);
    width: var(--modal-width);
    height: var(--modal-height);
    font-size: 1em;
}

.modal-row {
    --margin: var(--inner-margin-size) var(--outer-margin-size);
    width: calc(100% - 2 * var(--margin));
	display: flex;
    flex-direction: row;    
    margin: var(--margin);
    align-items: center;
}

.modal-row p {    
    padding: 0.25em 0;
    margin: 0;
}

.modal-flex 
{    
    width: 100%;
	display: flex;
    flex-direction: column;    
    justify-content: space-between;
}

.icon-option {
    width: var(--icon-option-size);
    height: var(--icon-option-size);
    margin: var(--icon-option-margin);
    padding: var(--icon-option-padding);
    background: #FFF;
    border-radius: 50%;
    border: 2px hidden white;
}

.selected-icon {
    border: var(--icon-option-border) solid var(--modal-secondary-color);
    margin: calc(var(--icon-option-margin) - var(--icon-option-border));
    padding: var(--icon-option-padding);
    border-radius: 5px;
    background: #FFF;
    border-radius: 50%;
}

.pulse-red {
    animation: pulse-red 1s 1;
}

.pulse-red-inset {
    animation: pulse-red-inset 1s 1;
}

.create-profile-modal input[type=text] {
    font-size: 1em;
    width: calc(var(--modal-width) - 2*var(--outer-margin-size));
    padding:  var(--inner-margin-size);
    box-sizing: border-box;
    border: none;
}

.create-profile-modal input[type='checkbox'] { 
    width: var(--consent-checkbox-size);
    height: var(--consent-checkbox-size);
    margin: var(--inner-margin-size);
}

.consent-confirm {
    --available-space: calc(var(--modal-width) - 2*var(--outer-margin-size) - var(--consent-checkbox-size) - 2*var(--inner-margin-size));
    width: var(--available-space);
}

.img-button {
    display: flex;
    border: none;
    padding: 0;
    margin: 0 5px;
}

.img-button img {
    width:  30px;
    height: 30px;
    padding: 5px;
}

.delete-button {
    background: #F66055;
}

.delete-modal-button {
    width: 120px;
    padding: 10px;
    margin: 10px;
}

.profile-back-button {
    width: auto;
    padding: 15px var(--outer-margin-size);
    margin: var(--inner-margin-size) ;
}

.incidents-container {
    padding: var(--inner-margin-size) ;
}

.delete-button:hover:enabled {
    background: #E65045;
}

.add-profiles-container {
    margin-left: 5px;
}

.add-button {
    background: var(--fixit-green);
}

.upload-button {
    background: var(--fixit-yellow);
}

.download-button {
    background: var(--fixit-blue);
}

.fixit-calendar {
    width:100%;
    height: 100%;
    margin: var(--outer-margin-size) 0;
}

.fixit-calendar button:disabled {
    color: slategrey;
}

.fixit-calendar a {
    color: white;
}

.fixit-calendar .react-calendar__tile {
    padding: 4px;
    color: var(--fixit-green);
}

.fixit-calendar .react-calendar__tile--now {
    background: inherit;
    border: 2px solid var(--fixit-blue);
}

.fixit-calendar .react-calendar__tile--now:enabled:hover  {
    background: #e6e6e6;
}

.fixit-calendar .react-calendar__tile--active, .react-calendar__tile--hasActive, .react-calendar__tile--active:enabled:focus {
    background: inherit;
    border: 2px solid var(--fixit-green);
    padding: 2px;
}

.fixit-calendar .react-calendar__tile--active:enabled:hover, .react-calendar__tile--hasActive:enabled:hover {
    background: #e6e6e6;
}

.profile-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: var(--inner-margin-size);
}

.profile-settings {
    background: #ccc;
    height: 2.5em;
    width: 25em;
    display: flex;
    margin: var(--inner-margin-size);
}

.profile-picker-item {
    margin: var(--inner-margin-size) 0;
}

.profile-summary {
    display: flex;
    align-items: center;
    background: #ccc;
    height: 2.5em;
    width: 25em;
    margin: 0 var(--inner-margin-size);
}

.profile-summary-icon {
    width: 2em;
    height: 2em;
    align-items: flex-start;
    margin: 0 var(--inner-margin-size);
}

.profile-summary-name {
    text-align: center;
    width: 100%;
}

.profile-picker-button {
    height: 2.5em;
    width: 100px ;
    margin: 0 5px;
}

a{
  text-decoration: none;
} 

.coa-button {
  height: 2.5em;
  background-color: var(--ifixit-new-light-blue);
  width: 80px ;
  margin: 0 5px;

  img {
    align-self: left;
    height: 20px;
    width: 20px;
    margin: 0 5px;
  }

  p {
    flex-grow: 1;
    margin: 0;
    text-align: center;
  }
}


.create-profile-button {
    height: 2.5em;
    width: 10em;
    margin: 0 auto;
}

.fill {
    width: 100%;
    height: calc(100% - var(--options-button-height));
}

h1 {
    background-color: #ccc;
    text-align: center;
    border-radius: 10px;
}

p {
    text-align: center;
    border-radius: 10px;
}

.history-page {
    width: 60%;
}

.history-page h1 {
    background-color: var(--fixit-green);
    color: white;
    padding: 0.2em;
}

@media(max-width: 414px) {
    .profile-summary {
        display: flex;
        align-items: center;
        background: #ccc;
        height: 2.5em;
        width: 100%;
        margin: 0px 0px 5px;
    }    

    .profile-settings {
        background: #ccc;
        height: 2.5em;
        width: 100%;
        display: flex;
        margin: var(--inner-margin-size);
    }

    .profile-picker-item {
        margin: 5px;
        border-radius: 10px;
        border: 2px solid #ccc;
        padding: 5px;
    }

    .add-profiles-container {
        visibility: hidden;
    }
}