/* INFORMATION PAGES GLOBAL STYLES - FAQ, TOS,PRIVACY, TECHNICAL ASSISTANCE */

.info-content {
  margin: 2.8em auto;
  max-width: 960px;
  padding: 0 20px;
}

.info-content p {
  margin: 0;
  margin-bottom: 40px;
  text-align: left;
}

.info-content ol {
  margin: 40px 0;
}

.info-content li {
  margin-bottom: 20px;
}

.info-home-link {
  text-decoration: none;
  color: #4a69bd;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 0.8em;
}

.info-main-heading {
  margin: 0;
  font-weight: 800;
  font-size: 32px;
  margin-bottom: 60px;
  margin-top: 45px;
}

.info-sub-heading {
  font-size: 18px;
  margin: 0;
  margin-bottom: 40px;
}

ol li:last-of-type {
  margin-bottom: 40px;
}
