.about-menu-link {
  font-weight: 600;
  text-decoration: none;
  margin-right: 20px;
  color: #bbb;
  padding-bottom: 5px;
  border-bottom: 3px solid #bbb;
}

.about-menu-link.active {
  font-weight: 800;
  color: var(--ifixit-new-blue);
  text-decoration: none;
  border-bottom: 3px solid var(--ifixit-new-blue);
}

.about-section {
  display: grid;
  width: 100%;
  padding: 6rem 0;
  grid-template-columns: 40% 60%;
  justify-items: center;
}

@media (max-width: 767px) {
  .about-section {
    grid-template-columns: 100%;
    justify-items: center;
  }
}

.about-section:not(:last-of-type) {
  border-bottom: 2px solid #ddd;
}

.about p {
  color: #777;
  line-height: 30px;
  width: 100%;
}

.about-section-img-container img {
  width: 100%;
  height: auto;
}

/* MOBILE STYLES */

@media (max-width: 768px) {

.about-section {
    column-gap: 1rem;
}

  .about-section-img-container img {
    width: 90%;
    margin-bottom: 50px;
  }

  .about-section-img-container:last-of-type img {
    width: 190%;
  }

  .info-content {
    padding: 0 4rem;
  }

}

@media (max-width: 414px) {
    .about {
        margin-top: 2rem;
    }

    .about-menu {
        font-size: 14px;
    }

    .info-content {
        padding: 0 2rem;
    }

    .about-section {
        padding: 2rem 0; 
    }


    .about-section-img-container {
        width: 60%;
    }

    .about-section-img-container img {
      width: 100%;
      margin-bottom: 30px;
    }
  
    .about-section-img-container:last-of-type img {
      width: 190%;
    }
  }
