.modal.options-modal{
    max-width: var(--modals-max-width);
    font-size: 1rem;
}

.options-content {
    width: 80%;
    min-height: 50px;
    margin: 30px auto;
}

.options-list {
    margin: 30px;
}

.option-button {
    width: 140px;
    padding: 10px;
    margin: 10px;
}
